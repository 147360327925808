// import Vue from 'vue'
// import VueRouter from 'vue-router'
import { createWebHistory, createRouter } from "vue-router";
// import Home from '@/views/Home'
// import About from '@/views/About'
// import ClientManagement from '@/views/ClientManagement'
import {store} from '@/store/index.js'

const routes = [
    {
        path: "/",
        name: "Root",
        // component: ClientManagement,
        component: () => import(/* webpackChunkName: "queue" */ '../views/LicenseUsageInfo.vue'),
        meta: { authorize: ['admin'] }
    },

    // UserManagementBO

    {
        path: "/user_management_bo",
        name: "UserManagementBO",
        component: () => import(/* webpackChunkName: "user_management_bo" */ '../views/UserManagementBO.vue'),
        meta: { authorize: ['admin'] }
    },

    // ClientManagement
    {
        path: "/client_management",
        name: "ClientManagement",
        component: () => import(/* webpackChunkName: "client_management" */ '../views/ClientManagement.vue'),
        meta: { authorize: ['admin', 'user'] }
    },

    //

    {
        path: "/client_preferences",
        name: "ClientPreferences",
        component: () => import(/* webpackChunkName: "client_preferences" */ '../views/ClientPreferences.vue'),
        meta: { authorize: ['admin', 'user'] }
    },

    //

    {
        path: "/default_vocabs",
        name: "DefaultVocabs",
        component: () => import(/* webpackChunkName: "default_vocabs" */ '../views/DefaultVocabs.vue'),
        meta: { authorize: ['admin'] }
    },

    //

    {
        path: "/default_sa_preferences",
        name: "DefaultSAPreferences",
        component: () => import(/* webpackChunkName: "default_sa_preferences" */ '../views/DefaultSAPreferences.vue'),
        meta: { authorize: ['admin'] }
    },

    //

    {
        path: "/default_vc_preferences",
        name: "DefaultVCPreferences",
        component: () => import(/* webpackChunkName: "queue" */ '../views/DefaultVCPreferences.vue'),
        meta: { authorize: ['admin'] }
    },

    //

    {
        path: "/default_asr_preferences",
        name: "DefaultASRPreferences",
        component: () => import(/* webpackChunkName: "queue" */ '../views/DefaultASRPreferences.vue'),
        meta: { authorize: ['admin'] }
    },

    //

    {
        path: "/default_tts_preferences",
        name: "DefaultTTSPreferences",
        component: () => import(/* webpackChunkName: "queue" */ '../views/DefaultTTSPreferences.vue'),
        meta: { authorize: ['admin'] }
    },

    //

    {
        path: "/event_management",
        name: "EventManagement",
        component: () => import(/* webpackChunkName: "queue" */ '../views/EventManagement.vue'),
        meta: { authorize: ['admin'] }
    },

    //

    {
        path: "/license_usage_info",
        name: "LicenseUsageInfo",
        component: () => import(/* webpackChunkName: "queue" */ '../views/LicenseUsageInfo.vue'),
        meta: { authorize: ['admin'] }
    },

];

// router.beforeEach((to, from, next) => {
//     if(to.matched.some(record => record.meta.requiresAuth)) {
//         if (store.getters.isLoggedIn) {
//             next()
//             return
//         }
//         next('/login')
//     } else {
//         next()
//     }
// })




const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const { authorize } = to.meta;
    // const currentUser = authenticationService.currentUserValue;

    const currentUserRole = store.getters['users/getRole']

    console.log("current authorize", store.getters['users/getAuth'])

    console.log("authorize", authorize)
    console.log("currentUserRole", currentUserRole)

    let currentAuth = !!store.getters['users/getAuth']

    if (currentAuth) {
        // console.log("11111")
        // check if route is restricted by role
        if (authorize.length && !authorize.includes(currentUserRole)) {
            // console.log("2222222")
            // role not authorised so redirect to home page
            // return next({path: '/speech_analytics'});
            return next({path: '/login', query: { redirect: to.fullPath } });
        } else {
            next();
        }
    } else {
        // console.log("33333")
        next();
        // return next({path: '/login'});
        // return next({path: '/login', query: { redirect: to.fullPath } });
        // return next({path: '/login', query: { redirect: to.fullPath } });
    }
    // next();



    // if (store.getters['users/getAuth']) {
    //     if (authorize) {
    //         if (!currentUserRole) {
    //         //     console.log("1111111")
    //         //     // not logged in so redirect to login page with the return url
    //             return next({ path: '/login' });
    //         //     // return next({ path: '/login', query: { returnUrl: to.path } });
    //         //     // return next({ path: '/login', query: { returnUrl: '/speech_analytics'} });
    //         }
    //
    //         // check if route is restricted by role
    //         if (authorize.length && !authorize.includes(currentUserRole)) {
    //
    //             console.log("2222222")
    //             // role not authorised so redirect to home page
    //             // return next({path: '/speech_analytics'});
    //             return next({path: '/login'});
    //         } else {
    //             next();
    //         }
    //     } else {
    //         return next({path: '/login'});
    //     }
    // } else {
    //     return next({path: '/login'});
    // }
    // next();
    // next({ path: '/speech_analytics' });
})

// router.beforeEach((to, from, next) => {
//     // redirect to login page if not logged in and trying to access a restricted page
//     const { authorize } = to.meta;
//     // const currentUser = authenticationService.currentUserValue;
//
//     const currentUserRole = store.getters['users/getRole']
//
//     if (authorize) {
//         if (!currentUserRole) {
//             // not logged in so redirect to login page with the return url
//             return next({ path: '/login', query: { returnUrl: to.path } });
//         }
//
//         // check if route is restricted by role
//         if (authorize.length && !authorize.includes(currentUserRole)) {
//             // role not authorised so redirect to home page
//             return next({ path: '/' });
//         }
//     }
//
//     next();
// })

export default router
