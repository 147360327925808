<template>
  <div class="main">
    <div class="side-navbar d-flex justify-content-between flex-wrap flex-column" id="sidebar" :class="{ active_nav: sidebarActive }">
      <ul class="nav flex-column text-white mml-2 mt-2 mb-2" >

        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          <!--          <i class="fa fa-home"></i>-->
          <use xlink:href="/"/>
<!--          <img src="img/img.png" style="width: 43px; height: 43px; ">-->
<!--          <img src="img/bb_wt2.png" style="height: 26px; margin-top: 8px; margin-bottom: 9px; margin-left: 18px;">-->
          <div style="height: 26px; margin-top: 8px; margin-bottom: 9px; margin-left: 18px;" />

<!--          <span class="mml-2 small text-white">ВымпелКом Речевая аналитика</span>-->
          <span>

          </span>

        </a>
        <a class="active-btn">
          <button class="btn"
                  @click="toggleSidebar()"
          ><i class="fa fa-bars text-white"></i></button></a>

        <hr class="mt-2">
        <div class="row mt-2 mb-2"></div>


        <div v-if="sidebarActive" class="side-transition">
          <li class="nav-item mml-2" v-if="$store.getters['users/getRole'] !== 'user'">
            <a class="vc_text text-decoration-none" href="/user_management_bo"><i class="fa fa-users ml-4"></i> Управление пользователями BO</a>
            <!--          <router-link to="/speech_analytics">Речевая аналитика</router-link>-->
          </li>
          <li class="nav-item mml-2">
            <a class="vc_text text-decoration-none" href="/client_management"><i class="fa fa-users-cog ml-4"></i> Управление клиентами</a>
            <!--          <router-link to="/virtual_call">Виртуальный оператор</router-link>-->
          </li>
<!--          <li class="nav-item mml-2">-->
<!--            <a class="vc_text text-decoration-none" href="/client_preferences"><i class="fas fa-user-edit"></i> Настройка ЛК Клиента</a>-->
            <!--          <router-link to="/tts">Синтез речи</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item mml-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_vocabs"><i class="fa fa-font ml-4"></i> Настройка предустановленных словарей</a>-->
            <!--          <router-link to="/asr">Распознавание речи</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_sa_preferences"><i class="fa fa-cloud ml-4"></i> Настройка параметров «Речевой аналитики» по умолчанию</a>-->
            <!--          <router-link to="/user_management">Управление пользователями</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_vc_preferences"><i class="fa fa-headset ml-4"></i> Настройка параметров «Виртуального оператора» по умолчанию</a>-->
            <!--          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_asr_preferences"><i class="fa fa-spell-check ml-4"></i> Настройка параметров «Распознавания речи» по умолчанию</a>-->
            <!--          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_tts_preferences"><i class="fa fa-assistive-listening-systems ml-4"></i> Настройка параметров «Синтез речи» по умолчанию</a>-->
            <!--          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/event_management"><i class="fa fa-list ml-4"></i> Просмотр событий</a>-->
            <!--          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>-->
<!--          </li>-->
          <li class="nav-item mml-2 mt-2">
            <a class="vc_text text-decoration-none" href="/license_usage_info"><i class="fa fa-list-ol ml-4"></i> Отчетность по использованию лицензий </a>
            <!--          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>-->
          </li>
        </div>


        <div class="active-icons" v-if="!sidebarActive">
          <div class="row mt-2 mb-2"></div>
          <li class="nav-item mml-2" v-if="$store.getters['users/getRole'] !== 'user'">
            <a class="vc_text text-decoration-none" href="/user_management_bo"><i class="fa fa-users ml-4"></i></a>
            <!--          <router-link to="/speech_analytics">Речевая аналитика</router-link>-->
          </li>
          <li class="nav-item mml-2">
            <a class="vc_text text-decoration-none" href="/client_management"><i class="fa fa-users-cog ml-4"></i></a>
            <!--          <router-link to="/virtual_call">Виртуальный оператор</router-link>-->
          </li>
<!--          <li class="nav-item mml-2">-->
<!--            <a class="vc_text text-decoration-none" href="/client_preferences"><i class="fas fa-user-edit"></i></a>-->
<!--            &lt;!&ndash;          <router-link to="/tts">Синтез речи</router-link>&ndash;&gt;-->
<!--          </li>-->
<!--          <li class="nav-item mml-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_vocabs"><i class="fa fa-font ml-4"></i></a>-->
<!--            &lt;!&ndash;          <router-link to="/asr">Распознавание речи</router-link>&ndash;&gt;-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_sa_preferences"><i class="fa fa-cloud ml-4"></i></a>-->
<!--            &lt;!&ndash;          <router-link to="/user_management">Управление пользователями</router-link>&ndash;&gt;-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_vc_preferences"><i class="fa fa-headset ml-4"></i></a>-->
<!--            &lt;!&ndash;          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>&ndash;&gt;-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_asr_preferences"><i class="fa fa-spell-check ml-4"></i></a>-->
<!--            &lt;!&ndash;          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>&ndash;&gt;-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/default_tts_preferences"><i class="fa fa-assistive-listening-systems ml-4"></i></a>-->
<!--            &lt;!&ndash;          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>&ndash;&gt;-->
<!--          </li>-->
<!--          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">-->
<!--            <a class="vc_text text-decoration-none" href="/event_management"><i class="fa fa-list ml-4"></i></a>-->
<!--            &lt;!&ndash;          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>&ndash;&gt;-->
<!--          </li>-->
          <li class="nav-item mml-2 mt-2" v-if="$store.getters['users/getRole'] !== 'user'">
            <a class="vc_text text-decoration-none" href="/license_usage_info"><i class="fa fa-list-ol ml-4"></i></a>
            <!--          <router-link to="/numbers_connection">Подключение номеров к услугам</router-link>-->
          </li>
        </div>
      </ul>
      <hr>
    </div>
  </div>

</template>
<script>
// import { useStore } from 'vuex';
export default {
  name: "SideBar",
  setup()
  {
    // const store = useStore();
  },
  props: {
    sidebarProp: Boolean
  },
  data() {
    return {
      sidebarActive: true
    }
  },
  created() {
    this.sidebarActive = this.sidebarProp
    console.log("getRole sidebar", this.$store.getters['users/getRole'])
  },
  components: {
  },
  methods: {
    toggleSidebar() {
      if (this.sidebarActive) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }
      this.sidebarActive = !this.sidebarActive


    }
  }
}
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: #fff;
}

.side-navbar {
  width: 399px;
  height: 100%;
  position: fixed;
  margin-left: -320px;
  background-color: #1E2020;
  transition: 0.5s;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #ffffff26;
}

.nav-item {
  height:48px;
}

.my-container {
  transition: 0.4s;
}

.active_nav {
  margin-left: 0;
  color: white
}

/* for main section */
.active-cont {
  margin-left: 399px;
}

#menu-btn {
  background-color: #100901;
  color: #fff;
  margin-left: -62px;
}

.my-container input {
  border-radius: 2rem;
  padding: 2px 20px;
}

.mml-2 {
  margin-left: 20px;
}

.active-btn {
  position: absolute;
  /*left: 90%;*/
  right: 35px;
  top: 16px;
}

.active-icons {
  position: absolute;
  /*left: 90%;*/
  right: 35px;
  top: 60px;
  transition: opacity .5s;
}

.side-transition {
  transition: opacity .5s;
}

</style>
